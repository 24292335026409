<h1 class="page-heading-with-subheader">
  <span i18n="@@report.title">Reports</span>
</h1>
<div id="report-container">
  <app-page-tabs
    [activeValue]="activePage"
    [tabs]="tabs"
    (tabClicked)="setActivePage($event)"
  ></app-page-tabs>
</div>

<div id="search-and-date-picker-container">
  <div
    *ngIf="activePage !== ReportPage.PENDING_REPORT"
    class="date-picker-container"
  >
    <app-date-range-picker
      class="date-range-picker"
      [limitDate]="limitDate"
      [initFromDate]="initFromDate"
      [initToDate]="initToDate"
      (setToDate)="setToDate($event)"
      (setFromDate)="setFromDate($event)"
    ></app-date-range-picker>
  </div>

  <zaver-search
    *ngIf="activePage === ReportPage.ACCOUNTING_REPORT_V2"
    id="search-bar"
    placeholder="Search bank reference, title, amount..."
    i18n-placeholder="@@reportDisbursements.search.placeholder"
    [inputModel]="searchQuery"
    (inputModelChange)="searchUpdatedQuery($event)"
  ></zaver-search>
</div>

<app-accounting-report-v2
  *ngIf="activePage === ReportPage.ACCOUNTING_REPORT_V2 && !loading"
  [fromDate]="fromDate"
  [toDate]="toDate"
  [searchQuery]="searchQuery"
>
</app-accounting-report-v2>

<app-sales-report
  *ngIf="activePage === ReportPage.SALES_REPORT && !loading"
  [fromDate]="fromDate"
  [toDate]="toDate"
>
</app-sales-report>

<app-pending-report
  *ngIf="activePage === ReportPage.PENDING_REPORT && !loading"
>
</app-pending-report>
