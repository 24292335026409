import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PaginatedList } from '@core/dto/PaginatedList';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DailySummary, ReportService } from '@core/service/report.service';
import * as FileSaver from 'file-saver';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { AppStateService } from '@core/service/app-state.service';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { LocaleService } from '@core/service/locale.service';
import {
  ReportColumnDefinition,
  ReportTableRows,
} from '@zfb/ui/report-table/report-table.component';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css'],
})
export class SalesReportComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() fromDate: Date;
  @Input() toDate: Date;

  fetchingResponse: boolean;
  totalTransactions: number;
  totalSum: Currency;
  public paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  emptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Glasses.png',
    headingText: $localize`:@@salesReport.emptyState.headingText:No reports to&nbsp;fetch`,
    bodyText: $localize`:@@salesReport.emptyState.bodyText:Could not find any reports during selected&nbsp;date range.`,
  };

  merchantDefaultCurrency: CurrencyCode;

  dailySummaries: ReportTableRows<DailySummary> = {
    data: [],
  };

  dailySummariesColumns: ReportColumnDefinition<DailySummary>[] = [
    {
      columnDataTransformer: (dailySummary: DailySummary) =>
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          dailySummary.date,
          'shortDate'
        ),
      text: $localize`:@@salesReport.table.column.date:Date`,
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (dailySummary: DailySummary) =>
        dailySummary.transactions.toString(),
      text: $localize`:@@salesReport.table.column.transactions:Transactions`,
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (dailySummary: DailySummary) =>
        new CurrencyPipe(this.localeService.getCurrentLocale()).transform(
          dailySummary.sum,
          dailySummary.currency,
          ''
        ),
      text: $localize`:@@salesReport.table.column.volume:Volume`,
      columnStylingTransformer: () => `color: #4466EE`,
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (dailySummary: DailySummary) =>
        `(${dailySummary.currency.toString()})`,
      text: $localize`:@@salesReport.table.column.currency:Currency`,
    },
  ];

  constructor(
    protected auth: AuthenticationService,
    private reportService: ReportService,
    private appStateService: AppStateService,
    public localeService: LocaleService
  ) {
    super(auth);
    this.merchantDefaultCurrency = this.appStateService.getDefaultCurrency();
  }

  ngOnInit() {
    this.fetchReport();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeFromDate = changes['fromDate'];
    const changeToDate = changes['toDate'];

    if (
      (changeFromDate && !changeFromDate.firstChange) ||
      (changeToDate && !changeToDate.firstChange)
    ) {
      this.fetchReport();
    }
  }

  onPageChange($event) {
    this.paginationState.page = $event;
    this.fetchReport();
  }

  onPageSizeChange($event) {
    this.paginationState.pageSize = $event;
    this.paginationState.page = 1;
    this.fetchReport();
  }

  async fetchReport() {
    if (
      this.fromDate &&
      this.toDate &&
      this.fromDate instanceof Date &&
      this.toDate instanceof Date
    ) {
      this.fetchingResponse = true;
      return this.reportService
        .getDailySales(
          this.fromDate,
          this.toDate,
          this.paginationState.page - 1, // page-numbering is zero based on the server
          this.paginationState.pageSize
        )
        .subscribe(
          (res: PaginatedList<DailySummary>) => {
            this.dailySummaries.data = res.elements;
            this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
            this.paginationState.total = res.total;
            this.paginationState.numOfItems = this.dailySummaries.data.length;
            this.totalTransactions = this.dailySummaries.data.reduce(
              (totalTransactions, current) =>
                totalTransactions + current.transactions,
              0
            );
            this.totalSum = {
              amount: this.dailySummaries.data.reduce(
                (totalSum, current) => totalSum + current.sum,
                0
              ),
              currencyCode: this.merchantDefaultCurrency,
            };

            this.fetchingResponse = false;
          },
          (err) => {
            super.handleError(err);
            this.fetchingResponse = false;
          }
        );
    }
  }

  async downloadFile() {
    if (this.fromDate && this.toDate) {
      this.clearError();
      this.fetchReport();
      this.fetchingResponse = true;

      return this.reportService.getFile(this.fromDate, this.toDate).subscribe(
        (res) => {
          FileSaver.saveAs(
            new Blob([res.body], {
              type: 'applications/vnc.openxmlformats-officedument.spreadsheetxml.sheet',
            }),
            res.headers.get('content-disposition').split('"')[1]
          ); // Filename encased in quotes, get middle object of 3
        },
        (err) => {
          super.handleError(err);
          this.fetchingResponse = false;
          const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(err));
          if (httpError.status === 400) {
            this.errorMessage = $localize`:@@salesReport.getFile.error.tooLarge:The file you are trying to download contains too many lines. Please select a time interval with fewer than 5000 transactions.`;
          } else {
            this.errorMessage = $localize`:@@salesReport.getFile.error.other:Something went wrong while trying to download the report.`;
          }
        }
      );
    }
  }
}
