<div class="intro-paragraph">
  <span
    >Here you can create and download reports of all current pending entries for your business.
    </span
  >
</div>

<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<div *ngIf="fetchingResponse" class="page-loader-container">
  <app-page-loader></app-page-loader>
</div>

<div *ngIf="!fetchingResponse" id="summary-container">
  <div id="summaries">
    <div>
      <span class="medium2"
        >Pending total amount:</span
      >
      <span id="sum-span">{{
        totalSum | value: localeService.getCurrentLocale()
      }}</span>
    </div>
  </div>

  <button
    *ngIf="pendingEntries.data.length != 0"
    id="fetch-report-button"
    type="button"
    (click)="toggleViewDownloadFileModal()"
  >
    <span>Download report</span>
    <img class="icons" src="assets/icons/Icon-Download-Circle.svg" alt="" />
  </button>
</div>

<app-report-table
  *ngIf="!fetchingResponse && pendingEntries.data.length != 0"
  [columns]="pendingEntriesColumns"
  [rows]="pendingEntries"
>
</app-report-table>

<app-table-pagination
  *ngIf="!fetchingResponse && pendingEntries.data.length != 0"
  [paginationState]="paginationState"
  (pageChanged)="onPageChange($event)"
  (pageSizeChanged)="onPageSizeChange($event)"
>
</app-table-pagination>

<!-- Empty state when no reports exist for the given date-range -->
<app-empty-state
  *ngIf="!availablePendingEntryCount && !fetchingResponse"
  [emptyState]="emptyState"
>
</app-empty-state>

<zaver-details-modal
  *ngIf="activeSmallModalComponent"
  (onClose)="toggleViewDownloadFileModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
<app-view-download-file
  [downloadPending]="true"
  (deactivateModal)="toggleViewDownloadFileModal()"
></app-view-download-file>
</zaver-details-modal>
