import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface PillSelectorOption {
  text: string;
  value: string;
}

@Component({
  selector: 'zaver-pill-selector',
  templateUrl: './pill-selector.component.html',
  styleUrls: ['./pill-selector.component.scss'],
})
export class PillSelectorComponent implements OnInit {
  @Input() header: string;
  @Input() options: PillSelectorOption[] = [];

  @Input() selectedOption: PillSelectorOption;
  @Output() selectedOptionChange = new EventEmitter<PillSelectorOption>();
  constructor() {}

  ngOnInit(): void {
    if (!this.selectedOption) {
      // Default to first option if no selected option.
      this.selectedOption = this.options[0];
    }
  }

  onClickOption(option: PillSelectorOption): void {
    if (this.getIsOptionSelected(option)) {
      return;
    }

    this.selectedOption = option;
    this.selectedOptionChange.emit(this.selectedOption);
  }

  getIsOptionSelected(option: PillSelectorOption): boolean {
    return this.selectedOption === option;
  }
}
