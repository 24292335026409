<div class="pill-selector">
  <div *ngIf="header" class="header">{{ header }}</div>

  <div class="pill-container">
    <span
      *ngFor="let option of options"
      class="pill"
      [ngClass]="
        getIsOptionSelected(option)
          ? 'selected-background-color'
          : 'default-background-color'
      "
      (click)="onClickOption(option)"
    >
      {{ option.text }}
    </span>
  </div>
</div>
