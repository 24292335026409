import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import {
  PendingEntry,
  ReportEntrySummary,
  ReportService,
} from '@core/service/report.service';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { AppStateService } from '@core/service/app-state.service';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { LocaleService } from '@core/service/locale.service';
import {
  ReportColumnDefinition,
  ReportTableRows,
} from '@zfb/ui/report-table/report-table.component';
import { DatePipe } from '@angular/common';
import { PaginatedListWithSummary } from '@core/dto/PaginatedListWithSummary';

@Component({
  selector: 'app-pending-report',
  templateUrl: './pending-report.component.html',
  styleUrls: ['./pending-report.component.css'],
})
export class PendingReportComponent extends BaseComponent implements OnInit {
  fetchingResponse: boolean;
  totalSum: Currency;
  public paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };
  activeSmallModalComponent: boolean;

  emptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Glasses.png',
    headingText: $localize`:@@salesReport.emptyState.headingText:Inga rapporter att&nbsp;hämta`,
    bodyText: $localize`:@@salesReport.emptyState.bodyText:Det verkar inte finnas några rapporter under det valda&nbsp;datumintervallet.`,
  };

  modalTitle: string = 'Download file as...';
  modalColor: string = '#4466EE';
  merchantDefaultCurrency: CurrencyCode;
  availablePendingEntryCount: number;

  pendingEntries: ReportTableRows<PendingEntry> = {
    data: [],
  };

  pendingEntriesColumns: ReportColumnDefinition<PendingEntry>[] = [
    {
      columnDataTransformer: (pendingEntry: PendingEntry) =>
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          pendingEntry.processingDate,
          'shortDate'
        ),
      text: 'Date',
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (pendingEntry: PendingEntry) => pendingEntry.title,
      text: 'Title',
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (pendingEntry: PendingEntry) =>
        pendingEntry.amount.toString(),
      text: 'Amount',
      columnStylingTransformer: (pendingEntry: PendingEntry) => {
        if (pendingEntry.amount >= 0) {
          return `color: #00A880`;
        }

        return `color: #DB305E`;
      },
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (pendingEntry: PendingEntry) =>
        `(${pendingEntry.currencyCode.toString()})`,
      text: 'Currency',
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (pendingEntry: PendingEntry) =>
        pendingEntry.merchantReference,
      text: 'Merchant Ref.',
    },
    {
      cssClasses: 'align-right',
      columnDataTransformer: (pendingEntry: PendingEntry) =>
        pendingEntry.merchantCaptureReference,
      text: 'Merchant Cap.Ref.',
    },
  ];

  constructor(
    protected auth: AuthenticationService,
    private reportService: ReportService,
    private appStateService: AppStateService,
    public localeService: LocaleService
  ) {
    super(auth);
    this.merchantDefaultCurrency = this.appStateService.getDefaultCurrency();
  }

  ngOnInit() {
    this.totalSum = {
      amount: 0,
      currencyCode: this.merchantDefaultCurrency,
    };
    this.fetchReport();
  }

  onPageChange($event) {
    this.paginationState.page = $event;
    this.fetchReport();
  }

  onPageSizeChange($event) {
    this.paginationState.pageSize = $event;
    this.paginationState.page = 1;
    this.fetchReport();
  }

  async fetchReport() {
    this.fetchingResponse = true;

    return this.reportService
      .getPendingEntries(
        this.paginationState.page - 1, // page-numbering is zero based on the server
        this.paginationState.pageSize
      )
      .subscribe(
        (res: PaginatedListWithSummary<PendingEntry, ReportEntrySummary>) => {
          this.handleResponse(res);
        },
        (err) => {
          super.handleError(err);
          this.fetchingResponse = false;
        }
      );
  }

  handleResponse(
    res: PaginatedListWithSummary<PendingEntry, ReportEntrySummary>
  ) {
    if (res !== null) {
      this.pendingEntries.data = res.elements;
      this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
      this.paginationState.total = res.total;
      this.paginationState.numOfItems = this.pendingEntries.data.length;
      this.availablePendingEntryCount = res.summary.totalCount;
      this.totalSum = {
        amount: res.summary.totalAmount,
        currencyCode: this.merchantDefaultCurrency,
      };
    }

    this.fetchingResponse = false;
  }

  toggleViewDownloadFileModal() {
    this.activeSmallModalComponent = !this.activeSmallModalComponent;
  }
}
