<div class="stepper">
  <div class="step-counter">
    <div
      *ngFor="let step of steps; let i = index"
      class="step-bar"
      [ngClass]="getStepStatus(i)"
      [style.width]="getBarWidth()"
      (click)="goToStep(i)"
    ></div>
  </div>

  <div class="step-content">
    <ng-container *ngTemplateOutlet="currentStep"></ng-container>
  </div>

  <div *ngIf="!shouldHideDefaultButtons" class="navigation">
    <button (click)="previousStep()" [disabled]="isFirstStep()">
      Previous
    </button>
    <button (click)="nextStep()" [disabled]="isLastStep()">Next</button>
  </div>
</div>
