import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ReportService } from '@core/service/report.service';
import { ReportType } from '@core/dto/ReportInfo';
import * as FileSaver from 'file-saver';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppStateService } from '@core/service/app-state.service';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { Currency } from '@core/dto/CurrencyCode';
import { LocaleService } from '@core/service/locale.service';

export enum FilePreference {
  EXCEL_XLSX = 'EXCEL_XLSX',
  CSV_COMMA = 'CSV_COMMA',
}

@Component({
  selector: 'app-accounting-report',
  templateUrl: './accounting-report.component.html',
  styleUrls: ['./accounting-report.component.css'],
})
export class AccountingReportComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  hasCsvPreference: Boolean;
  hasCashoutEnabled: Boolean;

  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Input() onlyShowCashout: Boolean = false;

  checkoutVolume: Currency;
  checkoutEntries = 0;
  cashoutVolume: Currency;
  cashoutEntries = 0;

  fetchingResponse: boolean;
  errorMessage: string;
  public fetchSource = new Subject<string>();

  emptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Glasses.png',
    headingText: $localize`:@@salesReport.emptyState.headingText:No reports to&nbsp;fetch`,
    bodyText: $localize`:@@salesReport.emptyState.bodyText:Could not find any reports during selected&nbsp;date range.`,
  };

  constructor(
    private appState: AppStateService,
    private reportService: ReportService,
    protected auth: AuthenticationService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.toDate.setDate(this.toDate.getDate());
    this.hasCashoutEnabled = this.appState.merchantHasCashoutAccess();
    this.hasCsvPreference = false;
    this.fetchSource
      .pipe(switchMap(() => this.doFetchReportSummary()))
      .subscribe(
        (res) => {
          this.checkoutVolume = res.checkoutPayoutVolume;
          this.checkoutEntries = res.numberOfCheckoutEntries;
          if (this.hasCashoutEnabled) {
            this.cashoutVolume = res.cashoutPayoutVolume;
            this.cashoutEntries = res.numberOfCashoutEntries;
          }
          this.fetchingResponse = false;
        },
        (err) => {
          super.handleError(err);
          this.fetchingResponse = false;
        }
      );
    this.fetchMerchantDetails();
    this.fetchSource.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeFromDate = changes['fromDate'];
    const changeToDate = changes['toDate'];
    if (
      (changeFromDate && !changeFromDate.firstChange) ||
      (changeToDate && !changeToDate.firstChange)
    ) {
      this.fetchSource.next();
    }
  }

  setFromDate(input: Date) {
    this.fromDate = input;
    this.fetchSource.next();
  }

  setToDate(input: Date) {
    this.toDate = input;
    this.fetchSource.next();
  }

  async fetchMerchantDetails() {
    this.clearError();
    this.reportService.getMerchantReportPreference().subscribe(
      (res) => {
        this.hasCsvPreference = res.body;
      },
      (err) => {
        super.handleError(err);
        this.errorMessage = $localize`:@@accountingReport.fetchError:Something went wrong when rights were to be retrieved. Try refreshing the page.`;
      }
    );
  }

  doFetchReportSummary() {
    this.clearError();
    this.cashoutVolume = null;
    this.checkoutVolume = null;
    this.cashoutEntries = 0;
    this.checkoutEntries = 0;

    if (this.fromDate && this.toDate) {
      this.fetchingResponse = true;
      return this.reportService.getMerchantReportSummary(
        this.fromDate,
        this.toDate
      );
    }
  }

  downloadCheckoutExcel() {
    const filetype =
      'applications/vnc.openxmlformats-officedument.spreadsheetxml.sheet';
    this.downloadFile(FilePreference.EXCEL_XLSX, ReportType.CHECKOUT, filetype);
  }

  downloadCheckoutCsv() {
    const filetype = 'text/csv;charset=UTF-8';
    this.downloadFile(FilePreference.CSV_COMMA, ReportType.CHECKOUT, filetype);
  }

  downloadCashout() {
    const filetype = 'text/csv;charset=UTF-8';
    this.downloadFile(FilePreference.CSV_COMMA, ReportType.CASHOUT, filetype);
  }

  async downloadFile(
    filePreference: FilePreference,
    reportType: ReportType,
    filetype: string
  ) {
    this.clearError();
    if (this.fromDate && this.toDate) {
      this.fetchingResponse = true;
      return this.reportService
        .getMerchantReport(
          this.fromDate,
          this.toDate,
          filePreference,
          reportType
        )
        .subscribe(
          (res) => {
            this.fetchingResponse = false;
            FileSaver.saveAs(
              new Blob([res.body], { type: filetype }),
              res.headers.get('content-disposition').split('"')[1]
            ); // Filename encased in quotes, get middle object of 3
          },
          (err) => {
            super.handleError(err);
            this.fetchingResponse = false;
            const httpError: HttpErrorResponse = JSON.parse(
              JSON.stringify(err)
            );
            if (httpError.status === 400) {
              this.errorMessage = $localize`:@@salesReport.getFile.error.tooLarge:The file you are trying to download contains too many lines. Please select a time interval with fewer than 5000 transactions.`;
            } else {
              this.errorMessage = $localize`:@@salesReport.getFile.error.other:Something went wrong while trying to download the report.`;
            }
          }
        );
    }
  }
}
