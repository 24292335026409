import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { View } from '../payment-management/payment-management.interfaces';
import { DropdownListItemv2 } from '../vat-dropdown/vat-dropdown.component';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { AppStateService } from '@core/service/app-state.service';

@Component({
  selector: 'app-fixed-amount-refund',
  templateUrl: './fixed-amount-refund.component.html',
  styleUrls: ['./fixed-amount-refund.component.scss'],
})
export class FixedAmountRefundComponent implements OnInit {
  constructor(private appStateService: AppStateService) {}
  @Output() setTaxRatePrecent = new EventEmitter<number>();

  @Input() totalRefundableAmount: number = 0;
  @Input() fixedAmountRefundForm: FormGroup = null;
  @Input() taxRatePercentForm: FormGroup = null;
  @Input() setView: (view: View) => void;
  @Input() taxRateOptions: DropdownListItemv2[];
  @Input() selectedTaxRate: DropdownListItemv2;
  fullRefund: boolean = false;
  showError: boolean = false;

  currencyCode: CurrencyCode = CurrencyCode.SEK;
  refundAmountButtonText: string = '';

  ngOnInit(): void {
    this.currencyCode = this.appStateService.getDefaultCurrency();
    this.changeRefundAmountButtonText();
  }

  changeRefundAmountButtonText(): void {
    this.refundAmountButtonText = `Refund ${
      this.fixedAmountRefundForm.value.refundAmount || 0
    } ${this.currencyCode}`;
  }

  setFullRefund(fullRefund: boolean): void {
    if (fullRefund) {
      this.fixedAmountRefundForm.controls.refundAmount.setValue(
        this.totalRefundableAmount.toString(),
        {
          emitEvent: false,
        }
      );
    }

    this.fullRefund = fullRefund;
    this.changeRefundAmountButtonText();
  }

  setTaxRatePercent(option: number): void {
    this.setTaxRatePrecent.emit(option);
  }

  async onSubmit(): Promise<void> {
    if (
      (this.fixedAmountRefundForm.valid && this.taxRateOptions.length > 0) ||
      (this.fixedAmountRefundForm.valid && this.taxRatePercentForm.valid)
    ) {
      this.setView(View.CONFIRM_FIXED_AMOUNT_REFUND);
    } else {
      this.showError = true;
    }
  }
}
