import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentLibraryComponent } from './component-library.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { BrowserModule } from '@angular/platform-browser';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { BadgeComponent } from './badge/badge.component';
import { CheckoutModalComponent } from './checkout-modal/checkout-modal.component';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { InputSliderComponent } from './input-slider/input-slider.component';
import { ListSliderComponent } from './list-slider/list-slider.component';
import { FormInputComponent } from './form-input/form-input.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { SearchComponent } from './search/search.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { StatusPillComponent } from './status-pill/status-pill.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { DetailsModalComponent } from './details-modal/details-modal.component';
import { StatusPillSmallComponent } from './status-pill-small/status-pill-small.component';
import { TokenInputComponent } from './token-input/token-input.component';
import { SelectCountryComponent } from './select-country/select-country.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { ButtonLinkComponent } from './button-link/button-link.component';
import { SelectLocaleComponent } from './input-with-select-locale/select-locale.component';
import { PillSelectorComponent } from './pill-selector/pill-selector.component';
import { StepperComponent } from './stepper/stepper.component';

import { AnimatedFormInputComponent } from './animated-form-input/animated-form-input.component';
import { SecondaryButtonSubtleComponent } from './secondary-button-subtle/secondary-button-subtle.component';
import { SecondaryButtonOutlinedComponent } from './secondary-button-outlined/secondary-button-outlined.component';
import { LoadingDotsComponent } from './loading-dots/loading-dots.component';
import { Dropdownv2Component } from './dropdownv2/dropdownv2.component';
@NgModule({
  declarations: [
    ComponentLibraryComponent,
    CheckboxComponent,
    PrimaryButtonComponent,
    RadioButtonComponent,
    BadgeComponent,
    CheckoutModalComponent,
    AlertBoxComponent,
    InputSliderComponent,
    ListSliderComponent,
    FormInputComponent,
    AddButtonComponent,
    SearchComponent,
    DropdownComponent,
    StatusPillComponent,
    FormModalComponent,
    FormInputComponent,
    StatusPillSmallComponent,
    DetailsModalComponent,
    TokenInputComponent,
    SelectCountryComponent,
    CustomSelectComponent,
    ButtonLinkComponent,
    SelectLocaleComponent,
    SecondaryButtonSubtleComponent,
    SelectCountryComponent,
    CustomSelectComponent,
    AnimatedFormInputComponent,
    SecondaryButtonOutlinedComponent,
    LoadingDotsComponent,
    Dropdownv2Component,
    PillSelectorComponent,
    StepperComponent,
  ],
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, CommonModule],
  exports: [
    ComponentLibraryComponent,
    CheckboxComponent,
    PrimaryButtonComponent,
    RadioButtonComponent,
    BadgeComponent,
    CheckoutModalComponent,
    AlertBoxComponent,
    InputSliderComponent,
    ListSliderComponent,
    FormInputComponent,
    AddButtonComponent,
    SearchComponent,
    DropdownComponent,
    StatusPillComponent,
    FormModalComponent,
    FormInputComponent,
    StatusPillSmallComponent,
    DetailsModalComponent,
    TokenInputComponent,
    SelectCountryComponent,
    CustomSelectComponent,
    ButtonLinkComponent,
    SelectLocaleComponent,
    SecondaryButtonSubtleComponent,
    SelectCountryComponent,
    CustomSelectComponent,
    AnimatedFormInputComponent,
    SecondaryButtonOutlinedComponent,
    LoadingDotsComponent,
    Dropdownv2Component,
    PillSelectorComponent,
    StepperComponent,
  ],
})
export class ComponentLibraryModule {}
