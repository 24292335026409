<ng-container *ngIf="view === 'ADD_LINE_ITEM'">
  <app-add-line-item
    (setView)="getLineItems($event)"
    [initLineItems]="lineItems"
    (returnToSelection)="returnToSelection.emit($event)"
  ></app-add-line-item
></ng-container>

<div class="wrapper" *ngIf="view === 'CREATE_PAMYMENT_REQUEST'">
  <p class="heading" i18n="@@createPaymentRequestConsumer.heading">Summary</p>

  <div class="text-row first">
    <span i18n="@@createPaymentRequestConsumer.totalTax">VAT</span>
    <span>{{ totalTax | value: localeService.getCurrentLocale() }}</span>
  </div>

  <div class="text-row">
    <span i18n="@@createPaymentRequestConsumer.totalAmount">Total amount incl. VAT</span>
    <span>{{ totalAmount | value: localeService.getCurrentLocale() }}</span>
  </div>

  <form class="form" [formGroup]="form" novalidate>
    <div
      class="input-fields"
      [class.error]="form.controls.phone.invalid && showError"
    >
      <zaver-form-input
        [title]="getPhoneFormInputTitle()"
        placeholder="Mobile number"
        [error]="form.controls.phone.invalid && showError && !copyLinkSelected"
        [errorMessage]="getPhoneErrorMessage()"
        formControlName="phone"
        [inputMode]="'tel'"
        [size]="'large'"
        i18n-placeholder="@@createPaymentRequestConsumer.form.phone.placeholder"
        [showContent]="true"
      >
        <zaver-select-country
          [buttonId]="'select-country-code'"
          [items]="AVAILABLECOUNTRYCODES"
          (itemSelected)="setCountry($event)"
          [selectedItem]="selectedcountry"
        ></zaver-select-country>
      </zaver-form-input>
    </div>

    <div class="input-fields" [class.error]="form.invalid && showError">
      <app-date-picker
        [initialDate]="null"
        [minDateInput]="minDueDate"
        [maxDateInput]="maxDueDate"
        title="Link valid to"
        placeholder="No expiration date"
        [showError]="showError"
        [formName]="'dueDate'"
        [resetDueDateForm]="resetDueDateForm"
        i18n-title="@@createPaymentRequestConsumer.form.linkValidityTitle"
        i18n-placeholder="
          @@createPaymentRequestConsumer.form.linkValidityPlaceholder"
      ></app-date-picker>
    </div>

    <div
      class="input-fields"
      [class.error]="form.controls.reference.invalid && showError"
    >
      <zaver-form-input
        [title]="prFormTitleAlias"
        [placeholder]="prFormTitleAliasPlaceholder"
        [formControlName]="'reference'"
        [inputMode]="'decimal'"
        [size]="'large'"
        [error]="form.controls.reference.invalid && showError"
        errorMessage="Enter reference or order number (max. 255 characters)"
        i18n-errorMessage="
          @@createPaymentRequestConsumer.form.referenceErrorMessage"
      >
      </zaver-form-input>
    </div>

    <div
      *ngIf="showInstallmentsToggle"
      class="input-fields installments-toggle"
    >
      <zaver-checkbox
        formControlName="toggleInstallmentOptions"
        [text]="getAliasForToggleInstallments()"
      ></zaver-checkbox>
    </div>

    <div
      class="input-fields is-last"
      [class.error]="form.controls.description.invalid && showError"
    >
      <zaver-zfb-textarea
        formControlName="description"
        [error]="form.controls.description.invalid && showError"
        [title]="prFormDescriptionAlias"
        [placeholder]="prFormDescriptionAliasPlaceholder"
        errorMessage="Maximum 5000 characters"
        i18n-errorMessage="
          @@createPaymentRequestConsumer.form.descriptionErrorMessage"
      ></zaver-zfb-textarea>
    </div>

    <div class="responsive-flex-container">
      <div class="radio-button-container">
        <zaver-zfb-radio-button
          [color]="'green'"
          [selected]="!copyLinkSelected"
          (click)="setCopyLink(false)"
          text="Send as SMS"
          i18n-text="@@createPaymentRequestConsumer.sendSms"
        ></zaver-zfb-radio-button>
      </div>

      <div class="radio-button-container">
        <label>
        <zaver-zfb-radio-button
          [color]="'green'"
          [selected]="copyLinkSelected"
          (click)="setCopyLink(true)"
          text="Copy the link"
          i18n-text="@@createPaymentRequestConsumer.copyLink"
        ></zaver-zfb-radio-button>
      </label>
      </div>
    </div>
  </form>

  <div class="input-fields" *ngIf="errorMessage">
    <app-form-error [errorText]="errorMessage"> </app-form-error>
  </div>

  <div class="continue-back-buttons-container">
    <zaver-primary-button
      class="continue-button"
      text="Create payment link"
      [loading]="processing"
      (click)="submit()"
      i18n-text="@@createPaymentRequestConsumer.continue"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      class="back-button"
      text="Back"
      (click)="setView('ADD_LINE_ITEM')"
      i18n-text="@@createPaymentRequestConsumer.back"
    ></zaver-secondary-button-subtle>
  </div>
</div>
