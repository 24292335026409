import {
  Component,
  OnInit,
  Input,
  ContentChildren,
  QueryList,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'zaver-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() header: string;
  @Input() shouldHideDefaultButtons: boolean;

  @ContentChildren('step') steps!: QueryList<TemplateRef<number>>;
  currentStepIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  get currentStep() {
    return this.steps.toArray()[this.currentStepIndex];
  }

  nextStep(): void {
    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++;
      this.scrollToTop();
    }
  }

  previousStep(): void {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.scrollToTop();
    }
  }

  goToStep(index: number): void {
    if (index >= 0 && index < this.steps.length) {
      this.currentStepIndex = index;
      this.scrollToTop();
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  getStepStatus(index: number): string {
    return index <= this.currentStepIndex ? 'active' : '';
  }

  getBarWidth(): string {
    return `${100 / this.steps.length}%`;
  }

  isLastStep(): boolean {
    return this.currentStepIndex === this.steps.length - 1;
  }
}
