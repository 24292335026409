import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { formatDate } from '@angular/common';
import { Currency } from '@core/dto/CurrencyCode';
import {
  PaymentRequestCreationResponse,
  PaymentRequestService,
} from '@core/service/payment-request.service';
import { availableCountryCodes, setCountryFlag } from '@core/select-country';
import {
  formatPhoneNumber,
  Item,
  phoneNumberValidator,
} from '../../../../../../../web-component-library/projects/component-library/src/public-api';
import { AuthenticationService } from '@core/service/authentication.service';
import { AppStateService } from '@core/service/app-state.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@core/base.component';
import {
  calculateTax,
  calculateTotalArticleAmountIncludingTax,
  DePaymentRequestView,
  LineItem,
} from '@core/dto/paymentRequest/paymentRequest';
import {
  convertDEStringToSEDate,
  FormValidators,
} from '@shared/validators/validators';
import { Market } from '@core/dto/Market';
import { PaymentRequestRequest } from '@core/dto/PaymentRequestRequest';
import { LocaleService } from '@core/service/locale.service';
import { GuiCustomizationDto } from '@core/dto/user-details';

@Component({
  selector: 'app-de-create-payment-request-consumer',
  templateUrl: './de-create-payment-request-consumer.component.html',
  styleUrls: ['./de-create-payment-request-consumer.component.scss'],
})
export class DECreatePaymentRequestConsumerComponent
  extends BaseComponent
  implements OnInit
{
  @Output() onDone = new EventEmitter<string>();
  @Output() onClose = new EventEmitter();
  @Output() returnToSelection = new EventEmitter();

  form: FormGroup;
  minDueDate: string;
  maxDueDate: string;
  showError: boolean = false;
  today: Date = new Date();
  processing: boolean = false;
  view: DePaymentRequestView = DePaymentRequestView.ADD_LINE_ITEM;
  lineItems: LineItem[] = [];
  totalTax: Currency;
  totalAmount: Currency;
  readonly AVAILABLECOUNTRYCODES = availableCountryCodes;
  selectedcountry: Item;
  formSubscription: Subscription;
  copyLinkSelected: boolean = false;
  skipNotifyingBuyer: boolean = false;
  showInstallmentsToggle: boolean = false;
  market: Market = null;
  defaultPhoneCode: string;

  // Form title and placeholder for fields "title" & "description"
  // Set in ngOnInit
  prFormTitleAlias: string;
  prFormTitleAliasPlaceholder: string;
  prFormDescriptionAlias: string;
  prFormDescriptionAliasPlaceholder: string;

  constructor(
    public deviceService: DeviceDetectorService,
    public auth: AuthenticationService,
    public localeService: LocaleService,
    private formBuilder: FormBuilder,
    private paymentRequestService: PaymentRequestService,
    private appStateService: AppStateService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.market = this.appStateService.getMarket();
    this.selectedcountry = this.getSelectedCountry();
    this.showInstallmentsToggle = this.shouldShowInstallmentsToggle();

    this.minDueDate = formatDate(this.today, 'yyyy-MM-dd', 'sv');
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    this.maxDueDate = formatDate(maxDate, 'yyyy-MM-dd', 'sv');

    // customization & translation of form labels
    const txt: GuiCustomizationDto = this.appStateService.getGuiCustomizations() ?? new GuiCustomizationDto();

    // Reference / Order number
    this.prFormTitleAlias = txt.prFormTitleAlias ?? $localize`:@@createPaymentRequestConsumer.form.referenceTitle:Reference / Order number`;
    // For line items:
    // Enter a unique reference for your payment
    this.prFormTitleAliasPlaceholder = txt.prFormTitleAliasPlaceholder
      ?? txt.prFormTitleAlias
      ?? $localize`:@@createPaymentRequestConsumer.form.referencePlaceholder:Enter a unique reference for your payment`;
    // Additional information (optional)
    this.prFormDescriptionAlias = txt.prFormDescriptionAlias ?? $localize`:@@createPaymentRequestConsumer.form.descriptionTitle:Additional information (optional)`;
    // Add additional information about the purchase
    this.prFormDescriptionAliasPlaceholder = txt.prFormDescriptionAliasPlaceholder
      ?? txt.prFormDescriptionAlias
      ?? $localize`:@@createPaymentRequestConsumer.form.descriptionPlaceholder:Add additional information about the purchase`;

    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      phone: [
        this.defaultPhoneCode,
        [
          Validators.required,
          phoneNumberValidator(this.appStateService.getSupportedCountryCodes()),
        ],
      ],
      dueDate: [
        '',
        [
          FormValidators.dueDateValidator(
            this.today,
            this.maxDueDate,
            this.market
          ),
        ],
      ],
      reference: ['', [Validators.required, Validators.maxLength(255)]],
      description: ['', [Validators.maxLength(5000)]],
      toggleInstallmentOptions: [false],
    });

    this.formSubscription = this.form.controls.phone.valueChanges.subscribe(
      (value) => {
        this.selectedcountry = setCountryFlag(value, this.selectedcountry);
      }
    );
  }

  resetDueDateForm = (): void => {
    this.form.controls['dueDate'].setValue('');
  };

  submit(): void {
    const mandatoryFieldValid =
      this.form.controls['dueDate'].valid &&
      this.form.controls['reference'].valid &&
      this.form.controls['description'].valid;

    const phoneValid = this.form.controls['phone'].valid;
    const phoneValidOrCopyLink = this.copyLinkSelected || phoneValid;

    // phone is not mandatory if "copy link" is selected
    if (!(mandatoryFieldValid && phoneValidOrCopyLink)) {
      this.showError = true;
      return;
    }

    this.processing = true;
    let dueDateValue: Date = new Date(this.form.controls['dueDate'].value);
    if (this.market === Market.DE || this.market === Market.AT) {
      dueDateValue = convertDEStringToSEDate(
        this.form.controls['dueDate'].value
      );
    }

    const lineItems = this.lineItems.map((item) => ({
      title: item.title,
      description: item.description,
      totalAmount: Number(item.unitPrice) * item.quantity,
      unitPrice: Number(item.unitPrice),
      quantity: item.quantity,
      taxRatePercent: item.taxRatePercent,
      taxAmount: calculateTax([item], this.market).amount,
    }));

    const paymentRequest: PaymentRequestRequest = {
      lineItems: lineItems,
      value: this.totalAmount,
      description: this.form.value.description.trim() || null,
      phone: phoneValid
        ? formatPhoneNumber(this.form.value.phone.trim())
        : null,
      title: this.form.value.reference.trim(),
      skipNotifyingBuyer: this.copyLinkSelected,
      expiryDate: dueDateValue,
      hideInstallmentOptions: this.shouldHideInstallments(
        this.form.value.toggleInstallmentOptions
      ),
    };
    this.handleRequest(this.paymentRequestService.create(paymentRequest));
  }

  getLineItemAmount(lineItems: LineItem[]): void {
    this.totalTax = calculateTax(lineItems, this.market);
    this.totalAmount = calculateTotalArticleAmountIncludingTax(
      lineItems,
      this.market
    );
  }

  handleRequest(creationPromise: Promise<PaymentRequestCreationResponse>) {
    creationPromise
      .then((response: any) => {
        if (response && response.paymentRequestId && !this.copyLinkSelected) {
          this.onDone.emit('b2c');
        } else if (
          response &&
          response.paymentRequestId &&
          this.copyLinkSelected
        ) {
          this.paymentRequestService.paymentRequestId =
            response.paymentRequestId;
          this.onDone.emit('b2c_link');
        }
      })
      .catch((err) => this.handleError(err));
  }

  getLineItems(lineItems): void {
    this.lineItems = lineItems;
    this.getLineItemAmount(lineItems);
    this.setView(DePaymentRequestView.CREATE_PAMYMENT_REQUEST);
  }

  setView(view): void {
    this.view = view;
  }

  setCountry(selected) {
    this.form.controls.phone.setValue(selected.value);
  }

  getPhoneFormInputTitle(): string {
    if (this.copyLinkSelected) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.phoneTitle.Optional:Mobile number (optional)`;
    }

    return $localize`:@@createPaymentRequestConsumer.form.phone.phoneTitle.required:Mobile number`;
  }

  getPhoneErrorMessage(): string {
    const errors = this.form.controls.phone.errors;
    if (!errors) {
      return null;
    }
    if (errors['required']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.required:Please enter a mobile number`;
    } else if (errors['invalidFormat']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.invalidFormat:Please check the mobile number`;
    } else if (errors['invalidNumber']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.invalidNumber:Please check the mobile number`;
    } else if (errors['unsupportedCountryCode']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.unsupportedCountryCode:The specified country code is not supported`;
    }
    return null;
  }

  setCopyLink(value: boolean): void {
    this.copyLinkSelected = value;
  }

  getSelectedCountry(): Item {
    switch (this.market) {
      case Market.SE:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[0].value;
        return this.AVAILABLECOUNTRYCODES[0];
      case Market.NO:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[1].value;
        return this.AVAILABLECOUNTRYCODES[1];
      case Market.FI:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[2].value;
        return this.AVAILABLECOUNTRYCODES[2];
      case Market.AT:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[3].value;
        return this.AVAILABLECOUNTRYCODES[3];
      case Market.DE:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[5].value;
        return this.AVAILABLECOUNTRYCODES[5];
      default:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[0].value;
        return this.AVAILABLECOUNTRYCODES[0];
    }
  }

  shouldShowInstallmentsToggle(): boolean {
    if (this.market !== Market.SE && this.market !== Market.NO) {
      return false;
    }

    return this.appStateService.getCurrentUser().activeRepresentation.company
      .canHideInstallmentOptions;
  }

  getAliasForToggleInstallments(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();

    if (
      guiCustomizations !== null &&
      guiCustomizations.toggleInstallmentSettings &&
      guiCustomizations.toggleInstallmentSettings.termToggleInstallments
    ) {
      return guiCustomizations.toggleInstallmentSettings.termToggleInstallments;
    }

    return $localize`:@@createPaymentRequestConsumer.toggleInstallments:Hide installment payment`;
  }

  shouldHideInstallments(boxIschecked: boolean): boolean {
    // always show installment options for DE and AT
    if (this.market === Market.DE || this.market === Market.AT) {
      return false;
    }

    if (
      !this.appStateService.getCurrentUser().activeRepresentation.company
        .canHideInstallmentOptions
    ) {
      return false;
    }

    // the label for the checkbox can be customized per merchant --> can be set so that a checked box means either hide
    // or show installment options. This method inverts the value shown in the gui appropriately
    if (
      this.appStateService.getGuiCustomizations()?.toggleInstallmentSettings ===
      null
    ) {
      return false;
    }

    if (
      this.appStateService.getGuiCustomizations().toggleInstallmentSettings
        .trueMeansShowInstallments
    ) {
      return !boxIschecked;
    } else {
      return boxIschecked;
    }
  }
}
